<template>
  <v-container fluid>
    <v-card>
      <v-img
        src="@/assets/portada.jpg"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >
        <v-card-title>Insumos para Sublimación</v-card-title>
        <v-card-subtitle class="white--text"
          >Todo para el emprendedor, al por menor y mayor</v-card-subtitle
        >
      </v-img>

      <v-card-actions>
        <v-btn color="blue" dark to="/tienda">
          <v-icon>mdi-arrow-right</v-icon> Listado de productos
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-alert
      class="mt-2 pa-4"
      border="top"
      icon="mdi-cash-register"
      prominent
      color="red lighten-2"
      v-if="pedidosPendientesPago > 0"
      ><v-row align="center">
        <v-col class="grow">
          Tenes {{pedidosPendientesPago}} pedido{{
            pedidosPendientesPago > 1 ? "s" : ""
          }}
          pendiente de pago
        </v-col>
        <v-col class="shrink">
          <v-btn @click="$router.push('/cuenta')">VER PEDIDOS</v-btn>
        </v-col>
      </v-row></v-alert
    >
    <v-alert
      class="mt-2 pa-4"
      border="top"
      icon="mdi-basket"
      prominent
      color="green lighten-2"
      v-if="$store.state.carrito.productos.length > 0"
      ><v-row align="center">
        <v-col class="grow">
          Tenes {{$store.state.carrito.productos.length}} producto{{
            $store.state.carrito.productos.length > 1 ? "s" : ""
          }}
          en tu carrito por un total de $ {{ $store.state.carrito.subtotal }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="$router.push('/carrito')">VER CARRITO</v-btn>
        </v-col>
      </v-row></v-alert
    >

    <CatalogoHomepage titulo="Productos destacados"></CatalogoHomepage>
  </v-container>
</template>
<script>
import CatalogoHomepage from "../components/CatalogoHomepage";
export default {
  name: "Homepage",
  components: {
    CatalogoHomepage,
  },
  data: () => ({
    loadingProductos: true,
    productos: [],
    pedidosPendientesPago: 0,
  }),
  methods: {
    async obtenerProductosHome() {
      this.loadingProductos = true;
      try {
        const productos = await this.axios.post("/productos", {
          parametros: {
            destacados: true,
          },
        });
        this.productos = productos.data.docs;
      } catch (error) {
        console.log(error);
      }
      this.loadingProductos = false;
    },
    async pedidosPendientes() {
      const datos = await this.axios.get("/pedidosPendientes");
      if(datos && datos.data) this.pedidosPendientesPago = datos.data.n
    },
  },
  created() {
    this.obtenerProductosHome();
    this.pedidosPendientes();
  },
};
</script>

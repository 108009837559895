<template>
  <v-card class="my-2">
    <v-card-text>
      <ListadoProductos :titulo="titulo" :items="items" :cargando="cargando"></ListadoProductos>
      <v-btn color="blue" dark v-if="botonIr" :to="accionIr"
        ><v-icon>mdi-arrow-right</v-icon> Ver más</v-btn
      >
    </v-card-text>
  </v-card>
</template>
<script>
import ListadoProductos from './ListadoProductos'
export default {
  name: "CatalogoHomepage",
  components: {
    ListadoProductos
  },
  props: {
    titulo: { required: false, type: String, default: 'Lo mas visto' },
    botonIr: { required: false, type: Boolean, default: true },
    accionIr: { required: false, type: String, default: "/tienda" },
  },
  data: () => ({
    items: [],
    cargando: false
  }),
  computed: {},
  methods: {
     async obtenerProductos() {
      try {
        let consulta = await this.axios.post("/productos", {
          parametros: {
            destacados: true
          }
        });
        this.items = consulta.data.docs
      } catch (error) {
        console.log(error);
      }
     }
  },
  created() {
    this.obtenerProductos()
  }
};
</script>
